import ReactGA from 'react-ga';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const propertyDimensionId =
  process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_DIMENSION;
const sourceDimensionId =
  process.env.REACT_APP_GOOGLE_ANALYTICS_SOURCE_DIMENSION;

export function gaEvent(data) {
  if (trackingId) {
    ReactGA.event(data);
  }
}

export function gaModalView(data) {
  if (trackingId) {
    ReactGA.modalview(data);
  }
}

export function gaSet(fieldsObject, trackerNames) {
  if (trackingId) {
    ReactGA.set(fieldsObject, trackerNames);
  }
}

export function gaSetPropertyDimension(property) {
  if (trackingId && propertyDimensionId) {
    gaSet({ [propertyDimensionId]: property });
  }
}

export function gaSetSourceDimension(source) {
  if (trackingId && sourceDimensionId) {
    gaSet({ [sourceDimensionId]: source });
  }
}

export function sendPageView(location) {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
}
