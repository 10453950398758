import themes, { DEFAULT_THEME } from 'themes';
import queryString from 'query-string';
import { DEFAULT_PROPERTY, THEME_PROPERTY_MAP } from 'utils/constants';

const STORAGE_KEY_THEME_NAME = 'windcreek_booking_theme_name';

export function getTheme(themeName) {
  return themes[themeName];
}

export function getThemeName(location = null) {
  const searchString = location ? location.search : window.location.search;
  const params = queryString.parse(searchString);

  if (params.theme && themes[params.theme]) {
    setThemeNameInStorage(params.theme);
    return params.theme;
  }

  if (isThemeSet()) {
    return getThemeNameFromStorage();
  }

  return DEFAULT_THEME;
}

export function getDefaultPropertyForTheme() {
  const themeName = getThemeName();

  return THEME_PROPERTY_MAP[themeName] || DEFAULT_PROPERTY;
}

function isThemeSet() {
  return !!getThemeNameFromStorage();
}

function setThemeNameInStorage(themeName) {
  if (themes[themeName]) {
    localStorage.setItem(STORAGE_KEY_THEME_NAME, themeName);

    return true;
  }

  return false;
}

function getThemeNameFromStorage() {
  return localStorage.getItem(STORAGE_KEY_THEME_NAME);
}
